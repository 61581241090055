import React, {
    createContext,
    useContext,
    useState,
    ReactNode,
    useEffect,
    useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { DigitizationList } from "../adapters/ApiDigitizationLists";
import ApiDigitizationLists from "../adapters/ApiDigitizationLists";
import { EsQuery } from "../adapters/ApiTasks";
import { useNotification } from "./NotificationContext";

interface DigitizationListsContextProps {
    digitizationLists: DigitizationList[];
    numFound: number;
    page: number;
    setPage: (page: number) => void;
    pageSize: number;
    setPageSize: (pageSize: number) => void;

    createDigitizationList: (
        query: Object,
        intention_id: number
    ) => Promise<DigitizationList>;
    addDocumentsToDigitizationList: (
        digitization_list_id: number,
        query: Object
    ) => Promise<DigitizationList>;
    removeDocumentsFromDigitizationList: (
        digitization_list_id: number,
        query: Object
    ) => Promise<DigitizationList>;
    deleteDigitizationList: (digitization_list_id: number) => void;

    findDigitizationList: (
        digitization_list_id: number
    ) => Promise<DigitizationList>;

    onInitiateListCreation: (
        count: number,
        queryParams: EsQuery,
        intentionId: number
    ) => void;
    onInitiateListItemsAddition: (
        count: number,
        queryParams: EsQuery,
        digitizationListId: number
    ) => void;
    onInitiateListItemsRemoval: (
        count: number,
        queryParams: EsQuery,
        digitizationListId: number
    ) => void;
}

const DigitizationListsContext = createContext<
    DigitizationListsContextProps | undefined
>(undefined);

interface DigitizationListsProviderProps {
    children: ReactNode;
}

export const DigitizationListsProvider: React.FC<
    DigitizationListsProviderProps
> = ({ children }) => {
    const api = useMemo(() => new ApiDigitizationLists(), []);

    const { t } = useTranslation();
    const { showConfirmDialog } = useNotification();

    const [digitizationLists, setDigitizationLists] = useState<
        DigitizationList[]
    >([]);
    const [numFound, setNumFound] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);

    const createDigitizationList = async (
        query: Object,
        intention_id: number
    ): Promise<DigitizationList> => {
        try {
            const response = await api.createFromQuery(query, intention_id);
            setDigitizationLists([response.data, ...digitizationLists]);
            return response.data;
        } catch (error) {
            console.error(`Could not create digitization list: ${error}`);
            throw error;
        }
    };

    const addDocumentsToDigitizationList = async (
        digitization_list_id: number,
        query: Object
    ): Promise<DigitizationList> => {
        try {
            const response = await api.addDocumentsToExtraction(
                digitization_list_id,
                query
            );
            setDigitizationLists(
                digitizationLists.map((digitization_list) =>
                    digitization_list.id === digitization_list_id
                        ? response.data
                        : digitization_list
                )
            );
            return response.data;
        } catch (error) {
            console.error(`Could not create digitization list: ${error}`);
            throw error;
        }
    };

    const removeDocumentsFromDigitizationList = async (
        digitization_list_id: number,
        query: Object
    ): Promise<DigitizationList> => {
        try {
            const response = await api.removeDocumentsFromExtraction(
                digitization_list_id,
                query
            );
            setDigitizationLists(
                digitizationLists.map((digitization_list) =>
                    digitization_list.id === digitization_list_id
                        ? response.data
                        : digitization_list
                )
            );
            return response.data;
        } catch (error) {
            console.error(`Could not create digitization list: ${error}`);
            throw error;
        }
    };

    const deleteDigitizationList = async (digitization_list_id: number) => {
        api.delete(digitization_list_id)
            .then((response) => {
                setDigitizationLists(
                    digitizationLists.filter(
                        (digitization_list) =>
                            digitization_list.id !== digitization_list_id
                    )
                );
            })
            .catch((error) => {
                console.error(`Could not delete digitization list: ${error}`);
            });
    };

    const findDigitizationList = async (digitization_list_id: number) => {
        return (await api.get(digitization_list_id)).data;
    };

    const onInitiateListCreation = (
        count: number,
        queryParams: EsQuery,
        intentionId: number
    ) => {
        showConfirmDialog(
            t(
                `Are you sure you want to create digitization list from {{count}} selected documents?`,
                { count }
            ),
            () => createDigitizationList(queryParams, intentionId)
        );
    };

    const onInitiateListItemsAddition = (
        count: number,
        queryParams: EsQuery,
        digitizationListId: number
    ) => {
        showConfirmDialog(
            t(
                `Are you sure you want to add {{count}} selected documents to digitization list {{digitizationListId}}?`,
                { count, digitizationListId }
            ),
            () =>
                addDocumentsToDigitizationList(digitizationListId, queryParams)
        );
    };

    const onInitiateListItemsRemoval = (
        count: number,
        queryParams: EsQuery,
        digitizationListId: number
    ) => {
        showConfirmDialog(
            t(
                `Are you sure you want to remove {{count}} selected documents from digitization list {{digitizationListId}}?`,
                { count, digitizationListId }
            ),
            () =>
                removeDocumentsFromDigitizationList(
                    digitizationListId,
                    queryParams
                )
        );
    };

    useEffect(() => {
        console.debug(`Fetching extractions from server.`);
        api.getPage(page, pageSize)
            .then((response) => {
                setDigitizationLists(response.data.items);
                setNumFound(response.data.num_found);
            })
            .catch((error) => {
                console.error(`Could not receive extractions: ${error}`);
            });
    }, [page, pageSize, api]);

    const contextValue: DigitizationListsContextProps = {
        digitizationLists,
        numFound,
        page,
        setPage,
        pageSize,
        setPageSize,

        createDigitizationList,
        addDocumentsToDigitizationList,
        removeDocumentsFromDigitizationList,
        deleteDigitizationList,

        findDigitizationList,

        onInitiateListCreation,
        onInitiateListItemsAddition,
        onInitiateListItemsRemoval,
    };

    return (
        <DigitizationListsContext.Provider value={contextValue}>
            {children}
        </DigitizationListsContext.Provider>
    );
};

export const useDigitizationLists = (): DigitizationListsContextProps => {
    const context = useContext(DigitizationListsContext);

    if (!context) {
        throw new Error(
            "useExtractions must be used within a ExtractionsProvider"
        );
    }

    return context;
};

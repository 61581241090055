import React, { Fragment, useState } from "react";

interface DataTableLayoutProps {
    header?: React.ReactNode;
    sidebar?: React.ReactNode;
    datatable: React.ReactNode;
    paginator?: React.ReactNode;
    dialog?: React.ReactNode;
}

const DataTableLayout: React.FC<DataTableLayoutProps> = ({
    header,
    sidebar,
    datatable,
    paginator,
    dialog,
}) => {
    const [isSidebarVisible, setIsSidebarVisible] = useState<boolean>(
        sidebar !== undefined
    );

    return (
        <div className="layout-datatable">
            {header && <div className="header">{header}</div>}
            {sidebar && (
                <Fragment>
                    <div className="sidebar" hidden={!isSidebarVisible}>
                        {sidebar}
                    </div>
                    <button
                        className="toggle-sidebar-btn surface-50 text-500 border-2 border-round border-200"
                        onClick={() => setIsSidebarVisible(!isSidebarVisible)}
                    >
                        <i
                            className={`text-xs pi pi-chevron-${
                                isSidebarVisible ? "left" : "right"
                            }`}
                        />
                    </button>
                </Fragment>
            )}
            <div className="section">{datatable}</div>
            {paginator && <div className="paginator">{paginator}</div>}
            {dialog && <div className="dialog">{dialog}</div>}
        </div>
    );
};

export default DataTableLayout;

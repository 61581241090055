import React from "react";
import { useTranslation } from "react-i18next";
import { ActionType } from "../../types/ActionType";
import { useAccess } from "../../contexts/AccessContext";
import SubNavigationButtonGroup from "./SubNavigationButtonGroup";

interface SubNavigationTasksProps {}

const SubNavigationTasks: React.FC<SubNavigationTasksProps> = () => {
    const { t } = useTranslation("navigation");
    const { hasPermission } = useAccess();

    if (
        !hasPermission(ActionType.PlanTasks) ||
        !hasPermission(ActionType.AdminTasks)
    ) {
        return null;
    }

    return (
        <SubNavigationButtonGroup
            navigationButtons={[
                {
                    label: t("tasks"),
                    to: "/tasks",
                    action: ActionType.PlanTasks,
                },
                {
                    label: t("periodic-tasks"),
                    to: "/tasks/periodic",
                    action: ActionType.AdminTasks,
                },
            ]}
        />
    );
};

export default SubNavigationTasks;

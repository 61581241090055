import { Button } from "primereact/button";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Badge } from "primereact/badge";
import { TieredMenu } from "primereact/tieredmenu";
import { MenuItem } from "primereact/menuitem";
import { EsItemsSelectionProps } from "../../contexts/base/EsSelectionInjector";

interface EsSelectionMenuProps<T extends { id: string }> {
    selectionProps: EsItemsSelectionProps<T>;
    actionsMenuItems: MenuItem[];
    numSelectedLabelNs: string;
}

const EsSelectionMenu = <T extends { id: string }>({
    selectionProps,
    actionsMenuItems,
    numSelectedLabelNs,
}: EsSelectionMenuProps<T>) => {
    const { t } = useTranslation("elasticsearch");
    const { numSelected, areAllSelected, setAreAllSelected, cancelSelection } =
        selectionProps;

    const actionSelectorMenuRef = useRef<TieredMenu | null>(null);

    const toggleActionSelectorMenu = (event) => {
        if (actionSelectorMenuRef.current) {
            actionSelectorMenuRef.current.toggle(event);
        }
    };

    return (
        <div className="flex flex-column align-items-end">
            <div className="flex align-items-center gap-2">
                <div className="card flex justify-content-center">
                    <TieredMenu
                        id="popup_menu_left"
                        ref={actionSelectorMenuRef}
                        className="w-auto"
                        model={actionsMenuItems}
                        popup
                        submenuIcon="pi pi-chevron-left"
                    />
                </div>
                <Button
                    icon="pi pi-objects-column"
                    className="p-button-rounded p-button-text"
                    severity={numSelected > 0 ? "help" : "secondary"}
                    disabled={numSelected === 0}
                    onClick={toggleActionSelectorMenu}
                    aria-controls="popup_menu_left"
                    aria-haspopup
                    tooltip={t("selection.actions-menu")}
                    tooltipOptions={{ position: "top", showDelay: 500 }}
                />
                <Button
                    icon="pi pi-expand"
                    className="p-button-rounded p-button-text"
                    severity={!areAllSelected ? "info" : "secondary"}
                    disabled={areAllSelected}
                    onClick={() => setAreAllSelected(true)}
                    tooltip={t("selection.all")}
                    tooltipOptions={{ position: "top", showDelay: 500 }}
                />
                <Button
                    icon="pi pi-times"
                    className="p-button-rounded p-button-text"
                    severity={numSelected > 0 ? "info" : "secondary"}
                    disabled={numSelected === 0}
                    onClick={cancelSelection}
                    tooltip={t("selection.cancel")}
                    tooltipOptions={{ position: "top", showDelay: 500 }}
                />
            </div>
            <Badge
                className="surface-50 text-gray-600"
                value={t("num-selected", {
                    ns: numSelectedLabelNs,
                    count: numSelected,
                })}
            />
        </div>
    );
};

export default EsSelectionMenu;

enum MaterialType {
    Book = "Book",
    ContinuingResource = "ContinuingResource",
    Graphic = "Graphic",
    Map = "Map",
    Music = "Music",
    Other = "Other",
}

export { MaterialType };
